import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { Form as FinalForm } from 'react-final-form';
import { compose } from 'redux';

import { FieldTextInput, Form, NamedLink, PrimaryButton } from '../../../components';
import { injectIntl, intlShape } from '../../../util/reactIntl';
import * as validators from '../../../util/validators';

import LoginButtonsComponent from '../LoginButtonsComponent/LoginButtonsComponent';
import css from './LoginForm.module.css';

function LoginFormComponent(props) {
  return (
    <FinalForm
      {...props}
      render={fieldRenderProps => {
        const {
          rootClassName,
          className,
          formId,
          handleSubmit,
          inProgress,
          intl,
          invalid,
          authInfo,
          location,
          isBusiness,
          onSignUpClick,
          onBusinessSignUpClick,
        } = fieldRenderProps;

        // email
        const emailLabel = intl.formatMessage({
          id: 'log_in.email_label',
        });
        const emailPlaceholder = intl.formatMessage({
          id: 'log_in.email_placeholder',
        });
        const emailRequiredMessage = intl.formatMessage({
          id: 'log_in.email_required',
        });
        const emailRequired = validators.required(emailRequiredMessage);
        const emailInvalidMessage = intl.formatMessage({
          id: 'log_in.email_invalid',
        });
        const emailValid = validators.emailFormatValid(emailInvalidMessage);

        // password
        const passwordLabel = intl.formatMessage({
          id: 'log_in.password_label',
        });
        const passwordPlaceholder = intl.formatMessage({
          id: 'log_in.password_placeholder',
        });
        const passwordRequiredMessage = intl.formatMessage({
          id: 'log_in.password_required',
        });
        const passwordRequired = validators.requiredStringNoTrim(passwordRequiredMessage);

        const classes = classNames(rootClassName || css.root, className);
        const submitInProgress = inProgress;
        const submitDisabled = invalid || submitInProgress;

        const passwordRecoveryLink = (
          <NamedLink name="PasswordRecoveryPage" className={css.recoveryLink}>
            {intl.formatMessage({ id: 'log_in.forgot_password' })}
          </NamedLink>
        );

        return (
          <Form className={classes} onSubmit={handleSubmit}>
            <h1 className={css.logInLabel}>{intl.formatMessage({ id: 'log_in.log_in_label' })}</h1>
            <span className={css.signUpQuestion}>
              {intl.formatMessage({ id: 'log_in.sign_up_prompt_part_1' })}
              <br />
              <span className={css.signUpLabel} onClick={() => onSignUpClick()} role="button" tabIndex={0}>
                {intl.formatMessage({ id: 'log_in.sign_up_label' })}
              </span>
              {intl.formatMessage({ id: 'log_in.sign_up_prompt_part_2' })}
              <span className={css.signUpLabel} onClick={() => onBusinessSignUpClick()} role="button" tabIndex={0}>
                {intl.formatMessage({ id: 'log_in.business_sign_up_label' })}
              </span>
              .
            </span>
            <LoginButtonsComponent
              authInfo={authInfo}
              location={location}
              intl={intl}
              isLogin
              isBusiness={isBusiness}
            />
            <div>
              <FieldTextInput
                type="email"
                id={formId ? `${formId}.email` : 'email'}
                name="email"
                autoComplete="email"
                label={emailLabel}
                placeholder={emailPlaceholder}
                validate={validators.composeValidators(emailRequired, emailValid)}
              />
              <FieldTextInput
                className={css.password}
                type="password"
                id={formId ? `${formId}.password` : 'password'}
                name="password"
                autoComplete="current-password"
                label={passwordLabel}
                placeholder={passwordPlaceholder}
                validate={passwordRequired}
              />
            </div>
            <div className={css.bottomWrapper}>
              <div className={css.passwordRecoveryLinkWrapper}>{passwordRecoveryLink}</div>
              <PrimaryButton type="submit" inProgress={submitInProgress}>
                {intl.formatMessage({ id: 'log_in.log_in_label' })}
              </PrimaryButton>
            </div>
          </Form>
        );
      }}
    />
  );
}

LoginFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  form: null,
  inProgress: false,
};

const { string, bool } = PropTypes;

LoginFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  form: string,
  inProgress: bool,
  intl: intlShape.isRequired,
};

const LoginForm = compose(injectIntl)(LoginFormComponent);
LoginForm.displayName = 'LoginForm';

export default LoginForm;
