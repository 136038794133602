import React from 'react';
import { FormattedMessage } from 'react-intl';
import EnvelopeLogo from '../../../assets/icons/envelope-16.png';
import { SocialLoginButton } from '../../../components';
import routeConfiguration from '../../../routing/routeConfiguration';
import { apiBaseUrl } from '../../../util/api';
import { pathByRouteName } from '../../../util/routes';
import { FacebookLogo, GoogleLogo } from '../socialLoginLogos';

import css from './LoginButtonsComponent.module.css';

function LoginButtonsComponent({ authInfo, location, intl, isLogin, showEmailLogin, onEmailSignUpClick, isBusiness }) {
  const locationFrom = location.state && location.state.from ? location.state.from : null;
  const authInfoFrom = authInfo && authInfo.from ? authInfo.from : null;
  const from = locationFrom || authInfoFrom || null;

  const showFacebookLogin = !!process.env.REACT_APP_FACEBOOK_APP_ID;
  const showGoogleLogin = !!process.env.REACT_APP_GOOGLE_CLIENT_ID;
  const showSocialLogins = showFacebookLogin || showGoogleLogin;

  const getDefaultRoutes = () => {
    const routes = routeConfiguration();
    const baseUrl = apiBaseUrl();

    // Route where the user should be returned after authentication
    // This is used e.g. with EditListingPage and ListingPage
    const fromParam = from ? `from=${from}` : '';

    // Default route where user is returned after successfull authentication
    const defaultReturn = pathByRouteName('LandingPage', routes);
    const defaultReturnParam = defaultReturn ? `&defaultReturn=${defaultReturn}` : '';

    // Route for confirming user data before creating a new user
    const defaultConfirm = pathByRouteName(isBusiness ? 'BusinessConfirmPage' : 'ConfirmPage', routes);
    const defaultConfirmParam = defaultConfirm ? `&defaultConfirm=${defaultConfirm}` : '';

    return {
      baseUrl,
      fromParam,
      defaultReturnParam,
      defaultConfirmParam,
    };
  };

  const authWithFacebook = () => {
    const defaultRoutes = getDefaultRoutes();
    const { baseUrl, fromParam, defaultReturnParam, defaultConfirmParam } = defaultRoutes;
    window.location.href = `${baseUrl}/api/auth/facebook?${fromParam}${defaultReturnParam}${defaultConfirmParam}`;
  };

  const authWithGoogle = () => {
    const defaultRoutes = getDefaultRoutes();
    const { baseUrl, fromParam, defaultReturnParam, defaultConfirmParam } = defaultRoutes;
    window.location.href = `${baseUrl}/api/auth/google?${fromParam}${defaultReturnParam}${defaultConfirmParam}`;
  };

  const facebookButtonText = intl.formatMessage({ id: 'authentication_page.facebook_login' });
  const googleButtonText = intl.formatMessage({ id: 'authentication_page.google_login' });
  const emailLoginButtonText = intl.formatMessage({ id: 'authentication_page.email_login' });

  return (
    <div className={css.root}>
      {showFacebookLogin ? (
        <div className={css.socialButtonWrapper}>
          <SocialLoginButton type="button" onClick={() => authWithFacebook()}>
            <span className={css.buttonIcon}>{FacebookLogo}</span>
            {facebookButtonText}
          </SocialLoginButton>
        </div>
      ) : null}

      {showGoogleLogin ? (
        <div className={css.socialButtonWrapper}>
          <SocialLoginButton type="button" onClick={() => authWithGoogle()}>
            <span className={css.buttonIcon}>{GoogleLogo}</span>
            {googleButtonText}
          </SocialLoginButton>
        </div>
      ) : null}

      {!isLogin ? (
        <div className={css.socialButtonWrapper}>
          <SocialLoginButton type="button" onClick={() => onEmailSignUpClick()}>
            <img className={css.buttonIcon} src={EnvelopeLogo} />
            {emailLoginButtonText}
          </SocialLoginButton>
        </div>
      ) : null}

      {isLogin && showSocialLogins ? (
        <div className={css.socialButtonsOr}>
          <span className={css.socialButtonsOrText}>
            <FormattedMessage id="authentication_page.or" />
          </span>
        </div>
      ) : null}
    </div>
  );
}

export default LoginButtonsComponent;
