import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { Form as FinalForm } from 'react-final-form';
import { compose } from 'redux';

import { FieldTextInput, Form, PrimaryButton } from '../../../components';
import { injectIntl, intlShape } from '../../../util/reactIntl';
import * as validators from '../../../util/validators';

import css from './ConfirmSignupForm.module.css';
import Spacer from '../../../components/Spacer/Spacer';
import FieldDropdownSelect from '../../EditListingPage/EditListingWizard/FieldDropdownSelect';

const KEY_CODE_ENTER = 13;

function ConfirmSignupFormComponent(props) {
  return (
    <FinalForm
      {...props}
      render={formRenderProps => {
        const {
          rootClassName,
          className,
          formId,
          handleSubmit,
          inProgress,
          invalid,
          intl,
          onOpenTermsOfService,
          onOpenPrivacyPolicy,
          authInfo,
        } = formRenderProps;

        // email
        const emailLabel = intl.formatMessage({
          id: 'confirm_sign_up.email_label',
        });
        const emailPlaceholder = intl.formatMessage({
          id: 'confirm_sign_up.email_placeholder',
        });
        const emailRequiredMessage = intl.formatMessage({
          id: 'confirm_sign_up.email_required',
        });
        const emailRequired = validators.required(emailRequiredMessage);
        const emailInvalidMessage = intl.formatMessage({
          id: 'confirm_sign_up.email_invalid',
        });
        const emailValid = validators.emailFormatValid(emailInvalidMessage);

        // firstName
        const firstNameLabel = intl.formatMessage({
          id: 'confirm_sign_up.first_name_label',
        });
        const firstNamePlaceholder = intl.formatMessage({
          id: 'confirm_sign_up.first_name_placeholder',
        });
        const firstNameRequiredMessage = intl.formatMessage({
          id: 'confirm_sign_up.first_name_required',
        });
        const firstNameRequired = validators.required(firstNameRequiredMessage);

        // lastName
        const lastNameLabel = intl.formatMessage({
          id: 'confirm_sign_up.last_name_label',
        });
        const lastNamePlaceholder = intl.formatMessage({
          id: 'confirm_sign_up.last_name_placeholder',
        });
        const lastNameRequiredMessage = intl.formatMessage({
          id: 'confirm_sign_up.last_name_required',
        });
        const lastNameRequired = validators.required(lastNameRequiredMessage);

        // userName
        const userNameLabel = intl.formatMessage({
          id: 'confirm_sign_up.user_name_label',
        });
        const userNamePlaceholder = intl.formatMessage({
          id: 'confirm_sign_up.user_name_placeholder',
        });
        const userNameRequiredMessage = intl.formatMessage({
          id: 'confirm_sign_up.user_name_required',
        });
        const userNameRequired = validators.required(userNameRequiredMessage);

        // country
        const countryLabel = intl.formatMessage({
          id: 'confirm_sign_up.country_label',
        });
        const countryPlaceholder = intl.formatMessage({
          id: 'confirm_sign_up.country_placeholder',
        });
        const countryRequiredMessage = intl.formatMessage({
          id: 'confirm_sign_up.country_required',
        });
        const countryRequired = validators.required(countryRequiredMessage);

        const classes = classNames(rootClassName || css.root, className);
        const submitInProgress = inProgress;
        const submitDisabled = invalid || submitInProgress;

        const handleTermsKeyUp = e => {
          // Allow click action with keyboard like with normal links
          if (e.keyCode === KEY_CODE_ENTER) {
            onOpenTermsOfService();
          }
        };

        const termsLink = (
          <span
            className={css.termsLink}
            onClick={onOpenTermsOfService}
            role="button"
            tabIndex="0"
            onKeyUp={handleTermsKeyUp}
          >
            {intl.formatMessage({ id: 'authentication_page.terms_and_conditions_label' })}
          </span>
        );

        const privacyPolicyLink = (
          <span
            className={css.termsLink}
            onClick={onOpenPrivacyPolicy}
            role="button"
            tabIndex="0"
            onKeyUp={handleTermsKeyUp}
          >
            {intl.formatMessage({ id: 'authentication_page.privacy_policy_label' })}
          </span>
        );

        // If authInfo is not available we should not show the ConfirmForm
        if (!authInfo) {
          return;
        }

        // Initial values from idp provider
        const { email, firstName, lastName } = authInfo;

        const countryOptions = [
          { key: 'PL', value: 'PL', label: 'PL' },
          { key: 'LT', value: 'LT', label: 'LT' },
        ]

        return (
          <Form className={classes} onSubmit={handleSubmit}>
            <div>
              <FieldTextInput
                type="email"
                id={formId ? `${formId}.email` : 'email'}
                name="email"
                autoComplete="email"
                label={emailLabel}
                placeholder={emailPlaceholder}
                initialValue={email}
                validate={validators.composeValidators(emailRequired, emailValid)}
              />
              <FieldTextInput
                className={css.firstNameRoot}
                type="text"
                id={formId ? `${formId}.firstName` : 'firstName'}
                name="firstName"
                autoComplete="given-name"
                label={firstNameLabel}
                placeholder={firstNamePlaceholder}
                initialValue={firstName}
                validate={firstNameRequired}
              />
              <FieldTextInput
                className={css.lastNameRoot}
                type="text"
                id={formId ? `${formId}.lastName` : 'lastName'}
                name="lastName"
                autoComplete="family-name"
                label={lastNameLabel}
                placeholder={lastNamePlaceholder}
                initialValue={lastName}
                validate={lastNameRequired}
              />
              <FieldTextInput
                className={css.userNameRoot}
                type="text"
                id={formId ? `${formId}.displayName` : 'displayName'}
                name="displayName"
                autoComplete="displayName"
                label={userNameLabel}
                placeholder={userNamePlaceholder}
                validate={userNameRequired}
              />
              <Spacer size={28}/>
              <FieldDropdownSelect
                id="country"
                name="country"
                options={countryOptions}
                label={countryLabel}
                validate={countryRequired}
                placeholder={countryPlaceholder}
                autoComplete="off"
              />
            </div>

            <div className={css.bottomWrapper}>
              <p className={css.termsText}>
                {intl.formatMessage(
                  { id: 'authentication_page.terms_and_conditions_accept_label' },
                  { termsLink, privacyPolicyLink }
                )}
              </p>
            </div>

            <PrimaryButton type="submit" inProgress={submitInProgress} disabled={submitDisabled}>
              {intl.formatMessage({ id: 'confirm_sign_up.confirm_and_continue_label' })}
            </PrimaryButton>
          </Form>
        );
      }}
    />
  );
}

ConfirmSignupFormComponent.defaultProps = { inProgress: false };

const { bool, func } = PropTypes;

ConfirmSignupFormComponent.propTypes = {
  inProgress: bool,

  onOpenTermsOfService: func.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const ConfirmSignupForm = compose(injectIntl)(ConfirmSignupFormComponent);
ConfirmSignupForm.displayName = 'ConfirmSignupForm';

export default ConfirmSignupForm;
