import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Form as FinalForm } from 'react-final-form';
import { compose } from 'redux';

import { FieldTextInput, Form, PrimaryButton } from '../../../components';
import { injectIntl, intlShape } from '../../../util/reactIntl';
import * as validators from '../../../util/validators';

import LoginButtonsComponent from '../LoginButtonsComponent/LoginButtonsComponent';
import css from './SignupForm.module.css';
import FieldDropdownSelect from '../../EditListingPage/EditListingWizard/FieldDropdownSelect';
import Spacer from '../../../components/Spacer/Spacer';

const KEY_CODE_ENTER = 13;

function SignupFormComponent(props) {
  const [showEmailSignup, setShowEmailSignup] = useState(false);

  return (
    <FinalForm
      {...props}
      render={fieldRenderProps => {
        const {
          rootClassName,
          className,
          formId,
          handleSubmit,
          inProgress,
          invalid,
          intl,
          onOpenTermsOfService,
          onOpenPrivacyPolicy,
          authInfo,
          location,
          onLoginClick,
          isBusiness,
        } = fieldRenderProps;

        // email
        const emailLabel = intl.formatMessage({ id: 'sign_up.email_label' });
        const emailPlaceholder = intl.formatMessage({
          id: 'sign_up.email_placeholder',
        });
        const emailRequiredMessage = intl.formatMessage({
          id: 'sign_up.email_required',
        });
        const emailRequired = validators.required(emailRequiredMessage);
        const emailInvalidMessage = intl.formatMessage({
          id: 'sign_up.email_invalid',
        });
        const emailValid = validators.emailFormatValid(emailInvalidMessage);

        // password
        const passwordLabel = intl.formatMessage({
          id: 'sign_up.password_label',
        });
        const passwordPlaceholder = intl.formatMessage({
          id: 'sign_up.password_placeholder',
        });
        const passwordRequiredMessage = intl.formatMessage({
          id: 'sign_up.password_required',
        });
        const passwordMinLengthMessage = intl.formatMessage(
          {
            id: 'sign_up.password_too_short',
          },
          {
            minLength: validators.PASSWORD_MIN_LENGTH,
          }
        );
        const passwordMaxLengthMessage = intl.formatMessage(
          {
            id: 'sign_up.password_too_long',
          },
          {
            maxLength: validators.PASSWORD_MAX_LENGTH,
          }
        );
        const passwordMinLength = validators.minLength(passwordMinLengthMessage, validators.PASSWORD_MIN_LENGTH);
        const passwordMaxLength = validators.maxLength(passwordMaxLengthMessage, validators.PASSWORD_MAX_LENGTH);
        const passwordRequired = validators.requiredStringNoTrim(passwordRequiredMessage);
        const passwordValidators = validators.composeValidators(passwordRequired, passwordMinLength, passwordMaxLength);

        // firstName
        const firstNameLabel = intl.formatMessage({
          id: 'sign_up.first_name_label',
        });
        const firstNamePlaceholder = intl.formatMessage({
          id: 'sign_up.first_name_placeholder',
        });
        const firstNameRequiredMessage = intl.formatMessage({
          id: 'sign_up.first_name_required',
        });
        const firstNameRequired = validators.required(firstNameRequiredMessage);

        // lastName
        const lastNameLabel = intl.formatMessage({
          id: 'sign_up.last_name_label',
        });
        const lastNamePlaceholder = intl.formatMessage({
          id: 'sign_up.last_name_placeholder',
        });
        const lastNameRequiredMessage = intl.formatMessage({
          id: 'sign_up.last_name_required',
        });
        const lastNameRequired = validators.required(lastNameRequiredMessage);

        // userName
        const userNameLabel = intl.formatMessage({
          id: 'sign_up.user_name_label',
        });
        const userNamePlaceholder = intl.formatMessage({
          id: 'sign_up.user_name_placeholder',
        });
        const userNameRequiredMessage = intl.formatMessage({
          id: 'sign_up.user_name_required',
        });
        const userNameRequired = validators.required(userNameRequiredMessage);

        // country
        const countryLabel = intl.formatMessage({
          id: 'sign_up.country_label',
        });
        const countryPlaceholder = intl.formatMessage({
          id: 'sign_up.country_placeholder',
        });
        const countryRequiredMessage = intl.formatMessage({
          id: 'sign_up.country_required',
        });
        const countryRequired = validators.required(countryRequiredMessage);

        const classes = classNames(rootClassName || css.root, className);
        const submitInProgress = inProgress;

        const handleTermsKeyUp = e => {
          // Allow click action with keyboard like with normal links
          if (e.keyCode === KEY_CODE_ENTER) {
            onOpenTermsOfService();
          }
        };

        const termsLink = (
          <span
            className={css.termsLink}
            onClick={onOpenTermsOfService}
            role="button"
            tabIndex="0"
            onKeyUp={handleTermsKeyUp}
          >
            {intl.formatMessage({ id: 'authentication_page.terms_and_conditions_label' })}
          </span>
        );

        const privacyPolicyLink = (
          <span
            className={css.termsLink}
            onClick={onOpenPrivacyPolicy}
            role="button"
            tabIndex="0"
            onKeyUp={handleTermsKeyUp}
          >
            {intl.formatMessage({ id: 'authentication_page.privacy_policy_label' })}
          </span>
        );

        const signupLabel = isBusiness
          ? intl.formatMessage({ id: 'sign_up.business.sign_up_label' })
          : intl.formatMessage({ id: 'sign_up.sign_up_label' });

        const countryOptions = [
          { key: 'PL', value: 'PL', label: 'PL' },
          { key: 'LT', value: 'LT', label: 'LT' },
        ]

        return (
          <Form className={classes} onSubmit={handleSubmit}>
            <h1 className={css.signUpLabel}>{signupLabel}</h1>
            <span className={css.logInQuestion}>
              {intl.formatMessage({ id: 'sign_up.log_in_prompt' })}
              <span className={css.logInLabel} onClick={() => onLoginClick()}>
                {intl.formatMessage({ id: 'sign_up.log_in_label' })}
              </span>
            </span>

            {showEmailSignup ? (
              <div>
                <FieldTextInput
                  className={css.firstNameRoot}
                  type="text"
                  id={formId ? `${formId}.fname` : 'fname'}
                  name="fname"
                  autoComplete="given-name"
                  label={firstNameLabel}
                  placeholder={firstNamePlaceholder}
                  validate={firstNameRequired}
                />
                <FieldTextInput
                  className={css.lastNameRoot}
                  type="text"
                  id={formId ? `${formId}.lname` : 'lname'}
                  name="lname"
                  autoComplete="family-name"
                  label={lastNameLabel}
                  placeholder={lastNamePlaceholder}
                  validate={lastNameRequired}
                />
                <FieldTextInput
                  className={css.userNameRoot}
                  type="text"
                  id={formId ? `${formId}.displayName` : 'displayName'}
                  name="displayName"
                  autoComplete="displayName"
                  label={userNameLabel}
                  placeholder={userNamePlaceholder}
                  validate={userNameRequired}
                />
                <FieldTextInput
                  className={css.emailRoot}
                  type="email"
                  id={formId ? `${formId}.email` : 'email'}
                  name="email"
                  autoComplete="email"
                  label={emailLabel}
                  placeholder={emailPlaceholder}
                  validate={validators.composeValidators(emailRequired, emailValid)}
                />
                <FieldTextInput
                  className={css.password}
                  type="password"
                  id={formId ? `${formId}.password` : 'password'}
                  name="password"
                  autoComplete="new-password"
                  label={passwordLabel}
                  placeholder={passwordPlaceholder}
                  validate={passwordValidators}
                />
                <Spacer size={28}/>
                <FieldDropdownSelect
                  id="country"
                  name="country"
                  options={countryOptions}
                  label={countryLabel}
                  validate={countryRequired}
                  placeholder={countryPlaceholder}
                  autoComplete="off"
                />

                <div className={css.bottomWrapper}>
                  <p className={css.termsText}>
                    {intl.formatMessage(
                      { id: 'authentication_page.terms_and_conditions_accept_label' },
                      { termsLink, privacyPolicyLink }
                    )}
                  </p>
                </div>

                <PrimaryButton type="submit" inProgress={submitInProgress}>
                  {intl.formatMessage({ id: 'sign_up.sign_up_label' })}
                </PrimaryButton>
              </div>
            ) : (
              <LoginButtonsComponent
                authInfo={authInfo}
                location={location}
                intl={intl}
                isLogin={false}
                isBusiness={isBusiness}
                onEmailSignUpClick={() => setShowEmailSignup(true)}
              />
            )}
          </Form>
        );
      }}
    />
  );
}

SignupFormComponent.defaultProps = { inProgress: false };

const { bool, func } = PropTypes;

SignupFormComponent.propTypes = {
  inProgress: bool,

  onOpenTermsOfService: func.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const SignupForm = compose(injectIntl)(SignupFormComponent);
SignupForm.displayName = 'SignupForm';

export default SignupForm;
